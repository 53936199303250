// import vue
import { createApp } from 'vue'
import { createStore } from 'vuex'
import modules from '../packs/store'

const store = createStore({
    modules: modules
});

// import assets
import '../packs/icrt-png-logos.css'
import '../packs/icrt-svg-logos.css'
import '../packs/icrt-services-icons.css'
import '../packs/login.css'

// top level app
import LoginPage from '../packs/views/login_page'

// vuetify
import vuetify from '../packs/config/vuetify'

document.addEventListener('DOMContentLoaded', () => {

    // create app
    createApp(LoginPage).use(vuetify).use(store).mount('#app')

    window.onfocus = function() {
        try {
            const time = parseInt(document.getElementsByTagName('meta')['time'].content);
            const now = Math.round((new Date()).getTime() / 1000);
            if (time < now) window.location = window.location.href;
        } catch (error) {}
    }
});