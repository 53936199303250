<template>
    <v-app style="background-color:#0D3A4E;">
    <!--  Login Page -->
      <v-container fluid>
        <v-row  align="center" no-gutters style="height: 100%;">
          <v-col lg="7" md="6 ma-0" sm="12 mb-6" class="pr-lg-10 d-flex justify-end" >
            <v-card width="900" class="pa-md-8 d-flex flex-column align-center" variant="flat" color="#0D3A4E">

              <!--   ICRT Logo                 -->
              <a href="https://www.international-testing.org/" class="icrt-logo-block plLogoWhite" target="_blank" data-cy="icrt-logo">
              </a>

              <!--  Services                    -->
              <div class="d-flex align-center justify-center ma-10">
                <a class="text-decoration-none wrapper mx-1 md-icon-block" :class="item.icon" :href="item.to" v-for="(item, index) in icrtServicesList"  :key="index" target="_blank" :aria-label="item.name.join('')"></a>
              </div>
            </v-card>
          </v-col>
          <!--   Login Form       -->
          <v-col lg="5" md="6 ma-0" sm="12" class="pl-lg-10 d-flex justify-start">
              <LoginForm :remote="false" title="ProjectLink"></LoginForm>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginForm from '../components/_login_form';

    export default {
      name: 'login-page',

      components: { LoginForm },

      computed: mapGetters([ 'icrtServicesList']),
    }
</script>


<style scoped>

.v-container {
  height: 100vh;
}

.wrapper {
  width: 50px;
  height: 50px;
  border-radius:5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  transition: transform 0.2s ease-in-out;
  margin: 0 4px;
  cursor: pointer;
}

.wrapper .image:hover {
  transform: scale(1.1);
}

</style>
